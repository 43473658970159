import React from "react";
import { Route } from "react-router-dom";

import Interactive from "../components/Interactive/Interactive";

const Track = (props) => {
  console.log(props);
  let trackList = props.data;
  return (
    <>
      <Route
        exact
        path="/track/:number"
        render={(props) => <Interactive {...props} trackData={trackList} />}
      ></Route>
    </>
  );
};

export default Track;
