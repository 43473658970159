import React from "react";
import { Route } from "react-router-dom";

//data
import trackData from "./data/trackData";

//templates
import Home from "./templates/Home";
import Shop from "./templates/Shop";
import Track from "./templates/Track";
import Info from "./templates/Info";

const Base = () => {

  const container = {
    height: "100%"
  }
  return (
    <div className="container" style={container}>
      <Route exact={true} path="/" render={() => <Home data={trackData} />} />
      <Route
        exact
        path="/track/:number"
        render={() => <Track data={trackData} />}
      />
      <Route path="/about" component={Info} />
    </div>
  );
};

export default Base;
