import React from "react";
import { Route } from "react-router-dom";

//components
import InfoModal from "../components/InfoModal/InfoModal";

const Home = (props) => {
  return (
    <div>
      <Route path="/about" render={() => <InfoModal />}></Route>
    </div>
  );
};

export default Home;
