export default [
  {
    name: "Luma",
    track_number: 1,
    link: "test link",
    duration: 1.5,
    visual_name: "LumaViz",
    track_file: './Visual/luma.mp3'
  },
  {
    name: "Colorwave",
    track_number: 2,
    link: "test link",
    duration: 4.07,
    visual_name: "Colorwave",
    track_file: './Visual/colorwave.mp3'
  },
  {
    name: "Organic",
    track_number: 3,
    link: `<iframe style="border: 0; width: 400px; height: 400px;" src="https://bandcamp.com/EmbeddedPlayer/track=3745742608/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://80cacao.bandcamp.com/track/organic">Organic by 80% Cacao</a></iframe>`,
    duration: 2.48,
    track_file: './Visual/organic.mp3'
  },
  {
    name: "Nuances",
    track_number: 4,
    link: "test link",
    duration: 3.44,
    visual_name: "NuancesViz",
    track_file: './Visual/nuances.mp3'
  },
  {
    name: "Oscillations",
    track_number: 5,
    link: "test link",
    duration: 2.52,
    visual_name: "OscillationsViz",
    track_file: './Visual/osc.mp3'
  },
  {
    name: "Crystal Feat. Substantial",
    track_number: 6,
    link: "test link",
    duration: 2.16,
    visual_name: "crystalViz",
    track_file: './Visual/crystal.mp3'
  },
  {
    name: "How You Feel",
    track_number: 7,
    link: "test link",
    duration: 2.16,
    visual_name: "hyfViz",
    track_file: './Visual/hyf.mp3'
  },
  {
    name: "Pure and Solid",
    track_number: 8,
    duration: 3.35,
    track_file: './Visual/pureandsolid.ogg'
  },
  {
    name: "Every Heart",
    track_number: 9,
    duration: 3.35,
    track_file: './Visual/everyheart.mp3'
  }
];
