import React from "react";
import { Route } from "react-router-dom";

//components
import TrackGrid from "../components/TrackGrid/TrackGrid";

const Home = (props) => {
	return (
		<>
			<Route path="/" render={() => <TrackGrid trackData={props.data} />}></Route>
		</>
	);
};

export default Home;
