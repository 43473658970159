import React,{ children } from "react";
import { Route } from "react-router-dom";
import "./styles.css";

//base
import Base from "./Base";

//components
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";

export default function App() {
  return (
    <div className="App">
      <Navigation />
      <Base>{children}</Base>
      <Route exact={true} path={ ["/","/about"]} render={ (props) => <Footer/> }></Route>
    </div>
  );
}
