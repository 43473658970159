import React from "react";
import "./info_modal.css";

const InfoModal = () => {
	const content = {
		width: "40%",
		margin: "auto",
	};

	const h1 = {
		fontSize: "4rem",
		fontWeight: "300",
	};

	return (
		<div>
			<div className="about-container" style={content}>
				<h1 style={h1}>About Luma</h1>
				<p>
					Luma is an audio visual project created by music artist{" "}
					<a href="https://80cacao.bandcamp.com/" target="_blank">
						80% Cacao
					</a>
					. The project - <b>Luma</b> (latin for "Light") highlights conversations through instrumentals. Topics circulate around self-esteem,
					self-evaluation and open-mindedness. Select a song to view the movement of light throughout space.
				</p>
			</div>
		</div>
	);
};

export default InfoModal;
