import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./interactive.css";

import Visual from "./Visual/Visual";
const tracksLinks = [];
const luma_file = require("./Visual/luma.mp3");
const colorwave_file = require("./Visual/colorwave.mp3");
const organic_file = require("./Visual/organic.mp3");
const nuances_file = require("./Visual/nuances.mp3");
const osc_file = require("./Visual/osc.mp3");
const crystal_file = require("./Visual/crystal.mp3");
const hyf_file = require("./Visual/hyf.mp3");
const everyheart_file = require("./Visual/everyheart.mp3");
const pureandsolid_file = require("./Visual/pureandsolid.mp3");

tracksLinks.push(luma_file, colorwave_file, organic_file, nuances_file, osc_file, crystal_file, hyf_file, pureandsolid_file, everyheart_file);

const Interactive = (props) => {
	const [playing, setIsPlaying] = useState(false);

	//styles
	const trackInfo = {
		position: "absolute",
		width: "100%",
		bottom: "0",
		padding: "10px 0 50px 0",
		background: "linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
	};

	const trackTitle = {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "80%",
		margin: "auto",
	};

	const btn = {
		padding: "10px",
		margin: "10px",
		fontSize: "1rem",
		cursor: "pointer",
	};

	//scoped variables
	const data = props.trackData;
	const dataLen = props.trackData.length;
	let history = useHistory();
	let urlParam;
	urlParam = parseInt(props.match.params.number, 10);

	//event handlers
	function handleGoForward(e) {
		e.preventDefault();
		setIsPlaying(false);
		if (urlParam > dataLen - 1) {
			history.push(`/track/${dataLen}`);
		} else {
			history.push(`/track/${urlParam + 1}`);
		}
	}

	function handleGoBack(e) {
		e.preventDefault();
		setIsPlaying(false);
		if (urlParam < 2) {
			history.push("/track/1");
		} else {
			history.push(`/track/${urlParam - 1}`);
		}
	}

	function homeHandle(e) {
		e.preventDefault();
		history.push(`/`);
	}

	//renderer
	let tracks = data.map((d) => {
		//10 being the radix param
		if (d.track_number === urlParam) {
			return (
				<>
					<p>{d.name}</p>
					<p>{`${d.track_number} / 9`}</p>
				</>
			);
		}
	});

	let trackFiles = tracksLinks.map((d) => {
		if (history.location.pathname === "/track/1") {
			return (
				<>
					<audio src={tracksLinks[0]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/2") {
			return (
				<>
					<audio src={tracksLinks[1]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/3") {
			return (
				<>
					<audio src={tracksLinks[2]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/4") {
			return (
				<>
					<audio src={tracksLinks[3]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/5") {
			return (
				<>
					<audio src={tracksLinks[4]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/6") {
			return (
				<>
					<audio src={tracksLinks[5]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/7") {
			return (
				<>
					<audio src={tracksLinks[6]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/8") {
			return (
				<>
					<audio src={tracksLinks[7]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/9") {
			return (
				<>
					<audio src={tracksLinks[8]}></audio>
				</>
			);
		} else if (history.location.pathname === "/track/10") {
			return (
				<>
					<audio src={tracksLinks[9]}></audio>
				</>
			);
		}
	});

	function handleChangePlayButton() {
		setIsPlaying(!playing);
	}

	return (
		<>
			<Visual />
			<div style={trackInfo}>
				{trackFiles}
				<button onClick={handleChangePlayButton} id="play" data-playing={playing} role="switch" aria-checked="false">
					<span>
						{playing ? (
							<svg className="pauseSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 45" x="0px" y="0px">
								<path d="M18,0A18,18,0,1,0,36,18,18,18,0,0,0,18,0Zm0,34A16,16,0,1,1,34,18,16.01817,16.01817,0,0,1,18,34Z" />
								<path d="M15,11a1,1,0,0,0-1,1V24a1,1,0,0,0,2,0V12A1,1,0,0,0,15,11Z" />
								<path d="M21,11a1,1,0,0,0-1,1V24a1,1,0,0,0,2,0V12A1,1,0,0,0,21,11Z" />
							</svg>
						) : (
							<svg className="playSvg" version="1.1" viewBox="0 0 100 125" xmlns="http://www.w3.org/2000/svg">
								<g transform="translate(0 -952.36)">
									<path
										transform="translate(0 952.36)"
										d="m50 14c-19.859 0-36 16.141-36 36 0 19.859 16.141 36 36 36s36-16.141 36-36c0-19.859-16.141-36-36-36zm0 4c17.697 0 32 14.303 32 32 0 17.697-14.303 32-32 32s-32-14.303-32-32c0-17.697 14.303-32 32-32zm-4.1875 18a2.0002 2.0002 0 0 0 -1.8125 2v24a2.0002 2.0002 0 0 0 3.3125 1.5l14-12a2.0002 2.0002 0 0 0 0 -3.0312l-14-12a2.0002 2.0002 0 0 0 -1.5 -0.46875zm2.1875 6.3438l8.9062 7.6562-8.9062 7.625v-15.281z"
									/>
								</g>
							</svg>
						)}
					</span>
				</button>
				<div className="trackTitle" style={trackTitle}>
					{tracks}
				</div>
				<div>
					<span style={btn} onClick={handleChangePlayButton} onClick={handleGoBack}>
						{" "}
						{"< <"}{" "}
					</span>
					<span style={btn} onClick={handleChangePlayButton} onClick={homeHandle}>
						{" "}
						Home{" "}
					</span>
					<span style={btn} onClick={handleChangePlayButton} onClick={handleGoForward}>
						{"> >"}
					</span>
				</div>
			</div>
		</>
	);
};

export default Interactive;
