import React from "react";
import { Route, Link, Switch } from "react-router-dom";

const Navigation = () => {
	const nav = {
		width: "100%",
		background: "black",
	};

	const navItems = {
		textDecoration: "none",
	};

	const navContainer = {
		width: "80%",
		display: "flex",
		margin: "auto",
		flexDirection: "row",
		justifyContent: "flex-end",
	};

	return (
		<>
			<nav style={nav}>
				<div style={navContainer} className="nav-items">
					<Link className="logo" style={{ order: "-1", marginRight: "auto" }} exact={true} to="/">
						<img style={{ width: "100px", height: "auto", textDecoration: "none" }} src={process.env.PUBLIC_URL + "/images/luma-cover.png"} />
					</Link>
					<Link style={navItems} to="/about">
						Luma Project
					</Link>
					<a href="https://80cacao.bandcamp.com/album/luma" target="_blank" style={navItems}>
						Buy Album
					</a>
				</div>
			</nav>
		</>
	);
};

export default Navigation;
