import React, { useState, useRef, useEffect } from "react";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { HalftonePass } from "three/examples/jsm/postprocessing/HalftonePass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import "./visual.css";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const Visual = (props) => {
	const three = useRef(null);

	useEffect(() => {
		const vshader = `
  varying vec2 v_uv;

  void main(){
    v_uv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
  
`;
		const fshader = `
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform vec3 u_mouse_click;
uniform float u_time;
uniform float u_soundTime;

vec2 random2(vec2 st){
    st = vec2( dot(st,vec2(127.1,311.7)),
              dot(st,vec2(269.5,183.3)) );
    return -1.0 + 2.0*fract(sin(st)*43758.5453123);
}

// Gradient Noise by Inigo Quilez - iq/2013
// https://www.shadertoy.com/view/XdXGW8
float noise(vec2 st) {
    vec2 i = floor(st) ;
    vec2 f = fract(st);

    vec2 u = f*f*(3.0-2.0*f);

    return mix( mix( dot( random2(i + vec2(0.0,0.0) ), f - vec2(0.0,0.0) ),
                     dot( random2(i + vec2(1.0,0.0) ), f - vec2(1.0,0.0) ), u.x),
                mix( dot( random2(i + vec2(0.0,1.0) ), f - vec2(0.0,1.0) ),
                     dot( random2(i + vec2(1.0,1.0) ), f - vec2(1.0,1.0) ), u.x), u.y);
}

void main() {
    vec2 st = gl_FragCoord.xy/u_resolution;
    vec3 color = vec3(0.0);

    float t = 1.0;
    // Uncomment to animate
    t = abs(sin(u_time))*30.;
    // Comment and uncomment the following lines:
    st += noise(st*2.)*t; // Animate the coordinate space
    st += noise(st/.13)*t;
    color = vec3(smoothstep(.30,.2,noise(st * 1000.0))); // Big black drops
    color += smoothstep(u_soundTime,0.2,noise(st)); // Black splatter
    color -= smoothstep(0.35,0.12,noise(st*90.)); // Holes on splatter

    gl_FragColor = vec4(color,1.0);
}
  
`;
		let camera, renderer, scene, light, mesh, uniforms, composer;

		const clock = new THREE.Clock();

		//audio analyser

		// select our play button
		const playButton = document.querySelector("#play");
		// get element for analysing
		var audioElement = document.querySelector("audio");
		// get new audio context instance
		var audioContext = new (window.AudioContext || window.webkitAudioContext)();
		//create analyser node
		var analyser = audioContext.createAnalyser();

		// create source
		var track = audioContext.createMediaElementSource(audioElement);
		// Connect the source to analyser
		track.connect(analyser);
		// take input node and connect it with destination
		track.connect(audioContext.destination);
		// set fast fourier transform
		analyser.fftSize = 2048;
		var dataArray = new Uint8Array(analyser.frequencyBinCount);

		init();
		animate();
		function init() {
			scene = new THREE.Scene();
			scene.background = new THREE.Color(0x000000);

			camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 1000);
			camera.position.set(0, 0, 10);

			const listener = new THREE.AudioListener();
			camera.add(listener);

			light = new THREE.DirectionalLight(0xffffff, 0x404040, 1);

			renderer = new THREE.WebGLRenderer();
			renderer.setSize(window.innerWidth, window.innerWidth);

			scene.add(camera);
			scene.add(light);

			const visualCanvas = document.getElementById("visualCanvas");
			visualCanvas.appendChild(renderer.domElement);
			window.addEventListener("resize", onWindowResize, false);

			// const controls = new OrbitControls(camera)
			// controls.rotateSpeed = 1.0;
			// controls.zoomSpeed = 1.2;
			// controls.panSpeed = 0.8;
			// controls.noZoom = false;
			// controls.noPan = false;
			// controls.staticMoving = true;
			// controls.dynamicDampingFactor = 0.3;
			// controls.update();

			const geometry = new THREE.PlaneGeometry(100, 50, 10);

			uniforms = {
				u_time: { value: 0.0 },
				u_soundTime: { value: 0.0 },
				u_color: { value: new THREE.Color(0xc1f7fd) },
				u_resolution: { value: { x: 0, y: 0 } },
				u_mouse: { value: { x: 0, y: 0 } },
				u_mouse_click: { value: { x: 0, y: 0 } },
			};

			const material = new THREE.ShaderMaterial({
				vertexShader: vshader,
				fragmentShader: fshader,
				uniforms: uniforms,
				uniformsNeedUpdate: true,
			});

			mesh = new THREE.Mesh(geometry, material);
			scene.add(mesh);

			var loaderBg = new THREE.TextureLoader();
			//post processing
			composer = new EffectComposer(renderer);
			const renderPass = new RenderPass(scene, camera);
			composer.addPass(renderPass);

			// const halftonePass = new HalftonePass(
			// 	0.1, // noise intensity
			// 	0.025, // scanline intensity
			// 	48, // scanline count
			// 	false // grayscale
			// );
			// halftonePass.renderToScreen = true;
			// composer.addPass(halftonePass);

			// const filmPass = new FilmPass(
			// 	0.8, // noise intensity
			// 	0.025, // scanline intensity
			// 	648, // scanline count
			// 	false // grayscale
			// );
			// filmPass.renderToScreen = true;
			// composer.addPass(filmPass);

			onWindowResize();
		}

		// get mouse coord
		document.addEventListener("mousemove", onMouseMove);
		function onMouseMove(event) {
			event.preventDefault();
			var mouse3D = new THREE.Vector3((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerheight) * 2 - 1, 0.5);

			uniforms.u_mouse.value.x = mouse3D.x;
			uniforms.u_mouse.value.y = mouse3D.y;
		}

		document.addEventListener("mousedown", onMouseDown);
		function onMouseDown(event) {
			event.preventDefault();
			var mouse3D = new THREE.Vector3((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerheight) * 2 - 1, 0.5);

			uniforms.u_mouse_click.value.x = mouse3D.x;
			uniforms.u_mouse_click.value.y = mouse3D.y;
		}

		// on click
		playButton.addEventListener(
			"click",
			function () {
				// check if context is in suspended state (autoplay policy)
				if (audioContext.state === "suspended") {
					audioContext.resume();
				}
				// play or pause track depending on state
				if (this.dataset.playing === "false") {
					audioElement.play();
					this.dataset.playing = "true";
				} else if (this.dataset.playing === "true") {
					audioElement.pause();
					this.dataset.playing = "false";
				}
			},
			false
		);

		// if finishes playing fire ended to stop playing
		audioElement.addEventListener(
			"ended",
			() => {
				playButton.dataset.playing = "false";
			},
			false
		);

		function onWindowResize(event) {
			const aspectRatio = window.innerWidth / window.innerHeight;
			let width, height;
			if (aspectRatio >= 1) {
				width = 1;
				height = (window.innerHeight / window.innerWidth) * width;
			} else {
				width = aspectRatio;
				height = 1;
			}
			camera.left = -width;
			camera.right = width;
			camera.top = height;
			camera.bottom = -height;
			camera.updateProjectionMatrix();
			renderer.setSize(window.innerWidth, window.innerHeight);
			uniforms.u_resolution.value.x = window.innerWidth;
			uniforms.u_resolution.value.y = window.innerHeight;
		}

		function animate() {
			requestAnimationFrame(animate);
			composer.render();

			uniforms.u_time.value = clock.getElapsedTime() * 0.0008;
			uniforms.u_soundTime.value = Math.cos(dataArray[100] * clock.getElapsedTime() * 0.2) * 0.02;
		}
	}, []);

	return (
		<>
			<div id="visualCanvas" ref={three}></div>
		</>
	);
};

export default Visual;
