import React from "react";
import "./footer.css";

const footer = {
	position: "fixed",
	bottom: "20px",
	width: "100%",
	paddingTop: "20px",
	marginBottom: "20px",
};

const caption = {
	fontSize: "10px",
};

const Footer = (props) => {
	return (
		<div style={footer} className="footer">
			<p style={{ fontSize: "10px" }}>Select the rotating text, turn on audio and adjust volume. Enjoy =)</p>
			<div className="social-icons">
				<a href="https://80cacao.bandcamp.com/">
					<img className="bc" src={require("./bc.svg")}></img>
				</a>
				<a href="https://twitter.com/80percentcacao">
					<img className="ig" src={require("./ig.svg")}></img>
				</a>
				<a href="https://soundcloud.com/80percentcacao">
					<img className="soundcloud" src={require("./soundcloud.svg")}></img>
				</a>
				<a href="https://twitter.com/80percentcacao">
					<img className="twitter" src={require("./twitter.svg")}></img>
				</a>
			</div>
			<p style={caption}>
				80% Cacao Music - Copyright 2021
				<br></br>
				<a href="https://www.digitalanthro.co/" target="_blank">
					Made by Digital Anthro
				</a>
			</p>
		</div>
	);
};

export default Footer;
